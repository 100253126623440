<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li>
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.name" placeholder="请输入姓名">
					</el-input>
				</li>
				<li>
					<el-button @click="search" color="#5473E8" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.avatar">
							<img src="../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="name" label="姓名" />
				<el-table-column property="phone" label="手机号码" />
				<el-table-column property="department" label="部门" />
				<el-table-column label="地区">
					<template #default="scope">
						{{scope.row.county!=null?scope.row.county.name:''}}{{scope.row.town!=null?scope.row.town.name:''}}{{scope.row.village!=null?scope.row.village.name:''}}
					</template>
				</el-table-column>
				<el-table-column property="name" label="职位">
					<template #default="scope">
						<p v-if="scope.row.position_type==0" type="success">督查室</p>
						<p v-if="scope.row.position_type==1" type="warning">县纪委</p>
						<p v-if="scope.row.position_type==2" type="danger">区域网格总长</p>
						<p v-if="scope.row.position_type==3" type="success">区域网格长</p>
						<p v-if="scope.row.position_type==4" type="warning">网格长</p>
						<p v-if="scope.row.position_type==5" type="danger">网格员</p>
					</template>
				</el-table-column>
				<el-table-column property="name" label="状态">
					<template #default="scope">
						<el-tag v-if="scope.row.check_status==1" type="success">已通过</el-tag>
						<el-tag v-if="scope.row.check_status==0" type="warning">待审核</el-tag>
						<el-tag v-if="scope.row.check_status==2" type="danger">未通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column property="address" label="操作" width="250">
					<template #default="scope">
						<el-button @click="dele(scope.row.id)" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background :current-page="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/trial";
	export default {
		name: 'users',
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				tableData: [],
				total: 0,
				tableloading: false,
				form: {
					page: 1,
					limit: 15,
					check_status: 1,
					name: ''
				},
				outerVisible: false,
				idlist: [],
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 1) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 1) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			// 清空
			reset() {
				this.form.name = ''
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 确定添加/编辑
			SubmitForm() {
				this.$refs.Form.SubmitForm()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 1) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
